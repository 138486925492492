<template>
	<el-container>
		<el-header>

			<div class="nav_right">
				<el-dropdown>
					<span>{{ username }}</span>
					<template #dropdown>
						<el-dropdown-menu>
							<!--              <el-dropdown-item>修改资料</el-dropdown-item>-->
							<!--              <el-dropdown-item>修改密码</el-dropdown-item>-->
							<el-dropdown-item @click="logout">退出</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
			<div class="nav_left logo">
				{{ logo }}
			</div>

		</el-header>
		<el-aside style="z-index: 0;">
			<div class="menu_hide">
				<el-menu :default-active="active" router>


					<el-submenu index="1">
						<template #title><i class="el-icon-menu"></i>工单管理</template>
						<el-menu-item index="/WorkOrder/WrokOrderAccept/List"
							v-hasPermission="'/Api/WrokOrderAccept/List'">工单受理</el-menu-item>
						<el-menu-item index="/WorkOrder/WrokOrderAccept/HistoryList"
							v-hasPermission="'/Api/WrokOrderAccept/List'">历史工单</el-menu-item>
					</el-submenu>
					<el-submenu index="2">
						<template #title><i class="el-icon-menu"></i>企业管理</template>
						<el-menu-item index="/BusinessManagement/BusinessManagement/List"
							v-hasPermission="'/Api/BusinessManagement/List'">企业管理</el-menu-item>
					</el-submenu>
					<el-submenu index="3">
						<template #title><i class="el-icon-menu"></i>用户管理</template>
						<el-menu-item index="/User/Logs/List" v-hasPermission="'/Api/Logs/List'">行为日志</el-menu-item>
						<el-menu-item index="/User/Role/List" v-hasPermission="'/Api/Role/List'">角色管理</el-menu-item>
						<el-menu-item index="/User/User/List" v-hasPermission="'/Api/User/List'">用户管理</el-menu-item>
					</el-submenu>
					<el-submenu index="4">
						<template #title><i class="el-icon-menu"></i>系统管理</template>
						<el-menu-item index="/System/SystemErrorLog/List" v-hasPermission="'/Api/SystemErrorLog/List'">
							错误日志</el-menu-item>
					</el-submenu>
					<el-submenu index="5">
						<template #title><i class="el-icon-menu"></i>工单报表</template>
						<el-menu-item index="/WorkOrderReport/WorkOrderReport/List"
							v-hasPermission="'/Api/WorkOrderReport/List'">工单报表</el-menu-item>
					</el-submenu>

				</el-menu>
			</div>
		</el-aside>
		<el-main>
			<router-view></router-view>
		</el-main>
		<el-footer>
			<div class="footDiv">系统名称：设备在线报修系统</div>
			<div class="footDiv">公司名称：苏州鼎正信息技术有限公司</div>
			<div class="footDiv">Copyright 苏州鼎正信息技术有限公司©2022</div>
		</el-footer>
	</el-container>
</template>

<script>
	import VueCookies from 'vue-cookies'

	export default {
		name: 'Console',
		data() {
			return {
				logo: "设备在线报修系统",
				username: window.global.userinfo.realNamer,
				footerContent: "系统名称：设备在线报修系统<br/>公司名称：苏州鼎正信息技术有限公司<br/>Copyright  苏州鼎正信息技术有限公司@2022",
			}
		},
		created() {
			this.active = /^(\/.*)/.exec(this.$route.path)[1].trim()
		},
		methods: {
			logout() {
				VueCookies.set("token", "")
				this.$router.push("/Login")
			}
		}
	}
</script>
<style scoped>
	.el-footer {
		height: auto !important;
		text-align: left !important;
	}

	.footDiv {
		width: 60%;
		padding-left: 40%;
		height: 20px;
		line-height: 20px;
		text-align: left;
	}
</style>
